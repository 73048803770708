







































































import Vue from 'vue'
import OrderService from '@/services/order'
import { mapMutations, mapState } from 'vuex'
import { MESSAGE_STATUS } from '@/store/modules/message/types'
import { DataEmitOrder } from '../index.vue'

export default Vue.extend({
  props: {
    paramOrderRequest: {
      type: Object as () => DataEmitOrder
    },

    visible: Boolean,
    orderPriceUpdated: Number,
    isOrderPriceEditByItSelt: Boolean
  },

  data(): { isLoadingSubmitButton: boolean } {
    return {
      isLoadingSubmitButton: false
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair', 'market']),

    // See: https://klaride.atlassian.net/browse/CMM-389
    isBitmexExchange(): boolean {
      return this.exchange.toUpperCase().includes('BITMEX')
    },

    bitmexLinearFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'linear'
      )
    },

    bitmexInverseFalseQuanto(): boolean {
      return (
        !!this.pair &&
        this.isBitmexExchange &&
        this.pair.isQuanto.toUpperCase() === 'FALSE' &&
        this.pair.linearInverse === 'inverse'
      )
    },

    bitmexQuantoOnly(): boolean {
      return !!this.pair && this.isBitmexExchange && this.pair.isQuanto.toUpperCase() === 'TRUE'
    },

    displayedUnitSymbolString(): string {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.pair.baseCurrency
          } else if (this.bitmexInverseFalseQuanto) {
            return this.pair.quoteCurrency
          } else if (this.bitmexQuantoOnly) {
            return 'Cont'
          }
          // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
        } else if (this.isDeribitExchange) {
          return this.pair.quoteCurrency
          // See: https://klaride.atlassian.net/browse/CMM-390
        } else if (this.isBinanceFutureExchange) {
          return 'Cont'
        } else {
          return this.pair.baseCurrency
        }
      }

      return this.paramOrderRequest.baseCurrency
    },

    displayedTotalSymbolString(): string {
      if (this.pair) {
        if (this.isBitmexExchange) {
          if (this.bitmexLinearFalseQuanto) {
            return this.pair.quoteCurrency
          } else if (this.bitmexInverseFalseQuanto) {
            return this.pair.baseCurrency
          } else if (this.bitmexQuantoOnly) {
            if (this.pair.settlementCurrency === 'XBt') {
              return 'USD'
            }
            return 'USDT'
          }
        } else if (this.isDeribitExchange) {
          return this.pair.baseCurrency
        } else {
          return this.pair.quoteCurrency
        }
      }

      return this.pair.quoteCurrency
    },

    // See: https://klaride.atlassian.net/browse/CMM-389?focusedCommentId=13386
    isDeribitExchange(): boolean {
      return this.exchange.toUpperCase().includes('DERIBIT')
    },

    displayedAmount(): number {
      return Number(this.paramOrderRequest.amount)
    },

    // See: https://klaride.atlassian.net/browse/CMM-390
    isBinanceFutureExchange(): boolean {
      return (
        this.exchange.toUpperCase().includes('BINANCEFUTURE') &&
        this.pair &&
        this.pair.contractMultiplier !== '' &&
        !isNaN(Number(this.pair.contractMultiplier))
      )
    }
  },

  methods: {
    ...mapMutations('flagTrigger', ['TOGGLE_ORDER_TRIGGER']),

    ...mapMutations('message', ['SET_MESSAGE']),

    closeDialog() {
      this.$emit('update:visible', false)
    },

    async submit() {
      this.isLoadingSubmitButton = true
      const dataReq = {
        exchange: this.paramOrderRequest.exchange,
        /*
        price:
          this.isOrderPriceEditByItSelt || !this.paramOrderRequest.isMarketPurpose
            ? Math.abs(Number(this.paramOrderRequest.price))
            : Math.abs(this.orderPriceUpdated * Number(this.paramOrderRequest.amount)),
        */
        price: Math.abs(Number(this.paramOrderRequest.orderPrice)),
        amount: Number(this.paramOrderRequest.amount),
        symbol: this.paramOrderRequest.symbol,
        positionType: this.paramOrderRequest.positionType,
        leg: this.paramOrderRequest.leg,
        text: this.paramOrderRequest.text,
        frontend: 'ui:web'
      }

      try {
        const tradiasExchange = ['tradiasTestnet', 'tradias'].includes(String(dataReq.exchange))

        tradiasExchange
          ? await OrderService.triggerOrderTradiasExchange(dataReq)
          : await OrderService.triggerOrderExchange(dataReq)

        // show notification
        this.SET_MESSAGE({
          type: MESSAGE_STATUS.SUCCESS,
          message: 'Order success!'
        })

        // trigger reload order
        this.TOGGLE_ORDER_TRIGGER()
      } finally {
        this.isLoadingSubmitButton = false
        this.$emit('update:visible', false)
      }
    }
  }
})
